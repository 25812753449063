import { useFormik } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { ReactComponent as CancelSvg } from 'assets/svg/cancel.svg';
import { ReactComponent as CheckboxSvg } from 'assets/svg/checkbox.svg';
import { ReactComponent as PlusSvg } from 'assets/svg/plus.svg';
import { ReactComponent as CancelYellowSvg } from 'assets/svg/v2/levels/cancel-yellow.svg';
import { BackButton, ButtonVariant, ForwardButton } from 'components/v2/buttons';
import { Form, FormActionButtonsRow, FormRow, Select, Input } from 'components/v2/forms/components';
import { StyledCheckbox } from 'components/v2/forms/components/checkbox/Checkbox.styled';
import { Paper } from 'components/v2/paper';
import { LEVEL_STEPS, listLevels } from 'constant';
import { levelBRoutes, levelConfigs } from 'constant/level-configs';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import { diversityOptionsWithSubOptions } from 'pages/v2/register/components/additional-information/util';
import levelActionsCreator from 'store/v2/level/actions';
import { DiversityFormValues } from 'store/v2/level/types';
import { SelectedDiversity } from 'store/v2/register/types';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  diversityOptionsSelector,
  diversityIsLoadingSelector,
  diversitySubOptionsSelector,
  diversitySubOptionsIsLoadingSelector,
} from 'store/v2/shared/selectors';
import { getOption } from 'utils';

import { diversityFormInitialValues, MAX_COUNT, validationSchema } from './form-config';
import {
  Paragraph,
  StyledButton,
  StyledButtonText,
  SubmittedDetails,
  SubmittedDetailsWrapper,
  SubmittedHeader,
  SubmittedValues,
  SubmittedValuesWrapper,
  Heading,
  StepDescription,
  StyledFormNotification,
  CharactersCounter,
  ErrorFormNotification,
} from '../../../Level.styled';

const Diversities: React.FC = () => {
  const genderCountItems = 11;
  const religionCountItems = 5;
  const raceEthnicityCount = 7;

  const dispatch = useDispatch();
  const diversityOptions = useSelector(diversityOptionsSelector);
  const isDiversityOptionsLoading = useSelector(diversityIsLoadingSelector);
  const diversitySubOptions = useSelector(diversitySubOptionsSelector);
  const diversitySubOptionsLoading = useSelector(diversitySubOptionsIsLoadingSelector);
  const [currentDiversityOtherStatus, setCurrentDiversityOtherStatus] = useState('');
  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.diversity);
  const [currentDiversity, setCurrentDiversity] = useState<SelectedDiversity | null>(null);

  const { values, isValid, handleSubmit, setFieldValue, touched, errors, handleBlur } = useFormik<DiversityFormValues>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      dispatch(
        levelActionsCreator.saveLevelFormValuesRequest(
          LEVEL_STEPS.diversity,
          values,
          true,
          levelBRoutes.finalStepLevel1B,
          true
        )
      );
    },
  });

  useEffect(() => {
    dispatch(sharedActionsCreator.diversityOptionsRequest());
  }, []);

  const remove = useCallback(
    (index: number) => {
      const updatedValues = values.diversity.filter((_val, idx) => idx !== index);
      setFieldValue('diversity', updatedValues);
    },
    [values.diversity]
  );

  const handleGoBack = useCallback(() => {
    dispatch(
      levelActionsCreator.saveLevelFormValuesRequest(
        LEVEL_STEPS.diversity,
        values,
        true,
        levelBRoutes.personalInterests
      )
    );
  }, [values]);

  const toggleIncludeOnResume = useCallback(
    (index: number) => {
      const updatedValues = values.diversity.map((val, idx) => {
        if (idx === index) {
          const { includeOnResume, ...rest } = val;
          return {
            ...rest,
            includeOnResume: !includeOnResume,
          };
        }
        return val;
      });
      setFieldValue('diversity', updatedValues);
    },
    [values.diversity]
  );

  const hasNotSelectedCategoryGender =
    values?.diversity.filter(item => item.category === 2).length === genderCountItems;

  const hasNotSelectedCategoryRaceEthnicity =
    values?.diversity.filter(item => item.category === 1).length === raceEthnicityCount;

  const hasNotSelectedCategoryReligion =
    values?.diversity.filter(item => item.category === 8).length === religionCountItems;

  const diversityIds = new Set(values?.diversity?.map(({ category }) => category));
  const filteredDiversityOptions = diversityOptions.filter(option => {
    if (
      (option.id === 2 && !hasNotSelectedCategoryGender) ||
      (option.id === 1 && !hasNotSelectedCategoryRaceEthnicity) ||
      (option.id === 8 && !hasNotSelectedCategoryReligion)
    ) {
      return option;
    }

    return !diversityIds.has(option.id);
  });

  const handleDiversityOptionChange = value => {
    if (value) {
      if (diversityOptionsWithSubOptions.includes(value?.id)) {
        dispatch(sharedActionsCreator.diversitySubOptionsRequest(value?.id));
      }
      setCurrentDiversity({
        category: value?.id,
        categoryName: value?.name,
      });
    } else {
      setCurrentDiversity(null);
    }
  };

  const selectedDiversitySubOptions = values?.diversity.filter(option => option.status).map(option => option.status);
  const filteredDiversitySubOptions = diversitySubOptions.filter(
    option => !selectedDiversitySubOptions.includes(option.id)
  );

  const handleDiversitySubOptionChange = value => {
    setCurrentDiversity(diversity => {
      if (!diversity) {
        return null;
      }

      if (value) {
        const obj: SelectedDiversity = {
          ...diversity,
          status: value.id,
          statusName: value.name,
        };

        return obj;
      } else {
        return {
          category: diversity?.category,
          categoryName: diversity?.categoryName,
          otherStatus: diversity?.otherStatus,
        };
      }
    });
  };

  const clearInputs = () => {
    setFieldValue('category', diversityFormInitialValues.category, false);
    setFieldValue('categoryName', diversityFormInitialValues.categoryName, false);
    setFieldValue('otherStatus', diversityFormInitialValues.otherStatus, false);
    setFieldValue('status', diversityFormInitialValues.status, false);
    setFieldValue('statusName', diversityFormInitialValues.statusName, false);
  };

  const handleAddDiversity = () => {
    if (currentDiversity === null) return;
    const currentDiversityWithOtherStatus: SelectedDiversity = {
      ...currentDiversity,
      otherStatus: currentDiversityOtherStatus,
    };

    setFieldValue('diversity', [
      ...values.diversity,
      { ...currentDiversityWithOtherStatus, added: true, includeOnResume: true },
    ]);
    setCurrentDiversity(null);
    setCurrentDiversityOtherStatus('');
    clearInputs();
  };

  useEffect(() => {
    debouncedSaveLevelFormValues(values);
  }, [values]);

  const hasSubOptions =
    currentDiversity?.category !== undefined && diversityOptionsWithSubOptions.includes(currentDiversity.category);

  const isShowAdditionalDetails =
    (currentDiversity &&
      currentDiversity?.category !== 1 &&
      currentDiversity?.category !== 2 &&
      currentDiversity?.category !== 8 &&
      !currentDiversity?.statusName) ||
    (currentDiversity as SelectedDiversity)?.statusName === 'Other';

  const isAdditionalDetail = category => {
    return category === 10 || (category !== 2 && category !== 1 && category !== 8);
  };

  const getEnteredCharsCount = useCallback(
    inputValues => {
      const currentFormCharCount =
        (currentDiversity?.categoryName?.length || 0) +
        (currentDiversity?.statusName ? currentDiversity.statusName.length + 3 : 0) +
        (currentDiversity?.otherStatus ? currentDiversity.otherStatus.length + 3 : 0);

      return inputValues.diversity.reduce((prev, cur) => {
        let otherLength = 0;

        if (isAdditionalDetail(cur.category)) {
          otherLength = cur.otherStatus ? cur.otherStatus.length + 3 : 0;
        } else {
          const otherStatus = cur.otherStatus || cur.otherStatus;
          otherLength = otherStatus ? otherStatus.length + 3 : 0;
        }

        return cur.includeOnResume ? prev + cur.categoryName.length + otherLength : prev;
      }, currentFormCharCount);
    },
    [values, currentDiversity]
  );

  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.diversity, values));
  }, 600);

  const currentCount = useMemo(() => getEnteredCharsCount(values), [values, currentDiversity]);

  return (
    <Form data-qa="my-diversities" className="container px-0" onSubmit={handleSubmit}>
      <FormRow>
        <div className="col-12">
          <Heading>My Diversities</Heading>
          <StepDescription>
            Diversity at Hive is ever-evolving, all-inclusive and self-identified. Sharing this information helps us
            understand and empower the next generation of talent into the workforce. You will also have the option to
            share this information with our partners when applying to opportunities.{' '}
          </StepDescription>
        </div>
      </FormRow>
      <Paper>
        <FormRow>
          <div className="col-12">
            <Select
              className="mb-0"
              name="category"
              label="Self-Identified Diverse Background"
              placeholder="Diversity"
              value={getOption(filteredDiversityOptions, currentDiversity?.category, 'name')}
              options={filteredDiversityOptions}
              onChange={handleDiversityOptionChange}
              isLoading={isDiversityOptionsLoading}
            />
            {hasSubOptions ? (
              <div className="col-12 mt-4">
                <Select
                  name="status"
                  placeholder="Additional Details"
                  options={filteredDiversitySubOptions}
                  value={getOption(filteredDiversitySubOptions, currentDiversity?.statusName, 'name')}
                  onChange={handleDiversitySubOptionChange}
                  isLoading={diversitySubOptionsLoading}
                  isClearable
                />
              </div>
            ) : null}
            {isShowAdditionalDetails ? (
              <div className="col-12">
                <Input
                  maxLength={28}
                  name="otherStatus"
                  className="mt-2 mb-0"
                  placeholder="Other Category (Write In)"
                  error={touched?.otherStatus ? errors?.otherStatus : ''}
                  onBlur={handleBlur}
                  value={currentDiversityOtherStatus}
                  onChange={e => {
                    setCurrentDiversityOtherStatus(e.target.value);
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className="col-12">
            <StyledButton
              variant={ButtonVariant.secondary}
              type="button"
              disabled={!currentDiversity || !isValid || currentCount > MAX_COUNT}
              onClick={handleAddDiversity}
            >
              <StyledButtonText>Add Diversity</StyledButtonText>
              <PlusSvg />
            </StyledButton>
          </div>
          {values?.diversity?.length >= 1 ? (
            <>
              <SubmittedHeader>Submitted Diversities</SubmittedHeader>
              <SubmittedDetailsWrapper>
                <SubmittedDetails>
                  <CheckboxSvg />
                  <p className="details">
                    Click to include/exclude on resume and profile. When included, the diversity will be noted with a
                    gold outline.
                  </p>
                </SubmittedDetails>
                <SubmittedDetails>
                  <CancelSvg />
                  <p className="details">Click to remove diversity from &quot;Submitted Diversities.&quot;</p>
                </SubmittedDetails>
              </SubmittedDetailsWrapper>
              <SubmittedValuesWrapper>
                <CharactersCounter error={currentCount > MAX_COUNT}>
                  {currentCount}/{MAX_COUNT}
                </CharactersCounter>
                {values?.diversity?.map((value, index) => (
                  <SubmittedValues
                    active={value.includeOnResume}
                    key={`${value.category}${value.statusName}${value.otherStatus}${index}`}
                  >
                    <div className="checkbox-wrapper">
                      <StyledCheckbox
                        type="checkbox"
                        checked={value.includeOnResume}
                        onChange={() => toggleIncludeOnResume(index)}
                      />
                      <Paragraph active={value.includeOnResume} className="truncate">
                        <span>
                          {value.categoryName}{' '}
                          {value.statusName && !isAdditionalDetail(value.category) && (
                            <span>({value.otherStatus ? value.otherStatus : value.statusName})</span>
                          )}
                        </span>
                        {isAdditionalDetail(value.category) && value.otherStatus && <span>({value.otherStatus})</span>}
                      </Paragraph>
                    </div>
                    <div className="svg-wrapper">
                      <button type="button" className="svg-button" title="remove" onClick={() => remove(index)}>
                        {value.includeOnResume ? (
                          <CancelYellowSvg aria-hidden="true" />
                        ) : (
                          <CancelSvg aria-hidden="true" />
                        )}
                      </button>
                    </div>
                  </SubmittedValues>
                ))}
              </SubmittedValuesWrapper>
            </>
          ) : null}
        </FormRow>
      </Paper>
      {!!currentDiversity && MAX_COUNT >= currentCount && (
        <StyledFormNotification>
          You entered a Diversity but need to select &quot;Add Diversity&quot; to move forward or clear it.
        </StyledFormNotification>
      )}
      {MAX_COUNT < currentCount && (
        <ErrorFormNotification>Sorry, you do not have enough space left.</ErrorFormNotification>
      )}
      <FormActionButtonsRow className="mt-4">
        <BackButton
          disabled={MAX_COUNT < currentCount || (!!currentDiversity && MAX_COUNT >= currentCount)}
          className="col-3"
          onClick={handleGoBack}
        >
          {levelConfigs[listLevels.ONE_B].goBackConfig.innerTitle}
        </BackButton>
        <ForwardButton
          disabled={MAX_COUNT < currentCount || (!!currentDiversity && MAX_COUNT >= currentCount)}
          className="col-6 col-md-3"
          type="submit"
        />
      </FormActionButtonsRow>
    </Form>
  );
};

export default Diversities;
